import { constants, resolveApiRoute } from '@dubb-app/core'
import { useApiFetch } from '~/composables/apiFetch'

export const useFavoriteTags = () => useState<Tag[]>('favoriteTags', () => [])

export function fetchFavoriteTags () {
  const favoriteTags = useFavoriteTags()

  const { data } = useApiFetch<Tag[]>(resolveApiRoute('api.tag.index', {
    filter: {
      type: constants.App_Models_Tag.TYPE_VIDEO,
      favorite: true
    }
  }), {
    lazy: true,
    key: 'favorite-tags',
    immediate: favoriteTags.value.length <= 0
  })

  watch(data, (newData) => {
    if (newData) {
      favoriteTags.value = newData
    }
  })
}
