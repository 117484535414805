import { resolveApiRoute } from '@dubb-app/core'
import { useApiFetch } from '~/composables/apiFetch'
import { useMe } from '~/composables/frontendState'

export const useTeamMembers = () => useState<TeamMember[]>('teamMembers', () => [])

export const useTeamMembersExceptMe = () => {
  const teamMembers = useTeamMembers()
  const me = useMe()

  return computed(() => (teamMembers.value ?? []).filter(m => m.id !== me.value.id))
}

export function fetchTeamMembers () {
  const teamMembers = useTeamMembers()

  const { data } = useApiFetch<TeamMember[]>(resolveApiRoute('api.team.member.index', { no_pagination: '1' }), {
    lazy: true,
    key: 'team-members',
    immediate: teamMembers.value.length <= 0
  })

  watch(data, (newData) => {
    if (newData) {
      teamMembers.value = newData
    }
  })
}
