import { resolveApiRoute } from '@dubb-app/core'
import { useApiFetch } from '~/composables/apiFetch'

export const useFrontendState = () => useState<FrontendState>('frontendState')
export const useMe = () => {
  const frontedState = useFrontendState()

  return computed(() => frontedState.value?.user)
}

export const fetchFrontendState = (opts?: any) => {
  const frontendState = useFrontendState()

  const { data } = useApiFetch<FrontendState>(resolveApiRoute('api.frontend_state'), {
    key: 'frontendState',
    immediate: !frontendState.value,
    ...opts
  })

  watch(data, (newData) => {
    if (newData) {
      frontendState.value = newData
    }
  })
}
